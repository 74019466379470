import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import { routerMiddleware } from 'react-router-redux';
import { createEpicMiddleware } from 'redux-observable';
import { setupListeners } from '@reduxjs/toolkit/query';

import reducers from '../containers/reducers';
import rootEpic from '../containers/epics';
import { persistConfig } from '../persist-migration';
import { feedInManagementApi } from '../containers/FeedInManagement/services';
import { dashboardApi } from '../containers/DashboardV2/services';
import { flexDashboardApi } from '../containers/FlexDashboard/services';
import { userApi } from '../containers/Authorization/services';
import { userDetailsApi } from '../containers/UserDetails/services';
import { paymentsApi } from '../services/payments';
import { InstalledSMApi } from '../containers/InstalledSM/services';
import { dataImportApi } from '../modals/DataImportModal/services';
import { DevicesTableApi } from '../containers/DevicesTable/services';
import { InvitedUsersApi } from '../containers/InvitedUsers/services';
import { loginApi } from '../containers/SignIn/services';

const nodeEnv = process.env.NODE_ENV;

const epicMiddleware = createEpicMiddleware();
export const history = createBrowserHistory();

const isDev = nodeEnv !== 'production';

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false }),
    routerMiddleware(history),
    epicMiddleware,
    feedInManagementApi.middleware,
    dashboardApi.middleware,
    flexDashboardApi.middleware,
    userApi.middleware,
    userDetailsApi.middleware,
    InstalledSMApi.middleware,
    dataImportApi.middleware,
    DevicesTableApi.middleware,
    InvitedUsersApi.middleware,
    loginApi.middleware,
    paymentsApi.middleware
  ],
  devTools: isDev ? {
    shouldHotReload: false,
    trace: true,
    actionsDenylist: '^.+redux-form\/'
  } : false,
  preloadedState: {}
});
setupListeners(store.dispatch);
epicMiddleware.run(rootEpic);

export const persistor = persistStore(store);

export default store;
