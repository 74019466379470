import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../api_helper';
import { PROVIDER_OAUTH_URL } from '../../../api/apiUrls';

export const userDetailsApi = createApi({
  reducerPath: 'userDetailsApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getFirmwareVersion: builder.query({
      query: ({ gateway }) => ({ url: `/firmware/current/${gateway}`, method: 'get' })
    }),
    startSoftwareUpdate: builder.mutation({
      refetchOnMountOrArgChange: false,
      query: ({ data }) => ({ url: '/firmware/latest', method: 'put', data })
    }),
    getShellhubData: builder.query({
      query: ({ supportId }) => ({ url: `/gateway/info?supportId=${supportId}`, method: 'get' })
    }),
    startOauth2ForProviders: builder.query({
      refetchOnMountOrArgChange: true,
      query: (data) => {
        const query = Object.keys(data).map((key) => `${key}=${data[key]}`).join('&');
        return { url: `${PROVIDER_OAUTH_URL}?${query}`, method: 'get' };
      },

      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          window.location.href = data.authUrl;
        } catch (error) {
          console.log(error, 'error');
        }
      }
    }),
    sendIOTCommand: builder.mutation({
      refetchOnMountOrArgChange: false,
      query: ({ data }) => ({ url: '/gateway/command', method: 'post', data })
    })
  })
});

export const {
  useStartSoftwareUpdateMutation,
  useGetShellhubDataQuery,

  useLazyGetFirmwareVersionQuery,
  useLazyStartOauth2ForProvidersQuery,

  useSendIOTCommandMutation
} = userDetailsApi;
